import styles from './addTrader.module.css';
import { Button, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import apiClient from '../../lib/apiClient';

const AddTrader = () => {
  const defaultData = {
    mode: "TESTING",
    leverage: 2,
    type: "UNLIMITED",
    direction: "BOTH"
  }
  const [open, setOpen] = useState(false);
  const [addTrader, setAddTrader] = useState(defaultData);

  const dismiss = async() => {
    setAddTrader(defaultData);
    setOpen(false);
  }

  const createTrader = async() => {
    try {
      if(!addTrader) {
        dismiss();
      }
      const {symbol, baseAmountIn, takeProfit, stepSize, stopLoss, mode, leverage, aim, type, lives, direction, hours} = addTrader;
      const data = {
        symbol,
        baseAmountIn,
        takeProfit,
        stepSize,
        stopLoss,
        mode,
        leverage,
        aim,
        type,
        lives, 
        direction,
        hours
      }
      const response = await apiClient.post("/", data);
      console.log(response);
      dismiss();
    }
    catch(error) {
      console.log(error);
      window.location.reload();
    }
  }

  return (
    <div className={styles.addTrader}>
      <Button type="primary" style={{ color: "#fff" }} onClick={() => setOpen(true)}>
        Add Trader
      </Button>
      <Modal
        title="Add Trader"
        open={open}
        okText={"Create"}
        onOk={createTrader}
        onCancel={() => setOpen(false)}
        okButtonProps = {{
          disabled: !addTrader || !addTrader?.symbol || !addTrader?.stepSize || !addTrader?.takeProfit,
          style: {color: "#fff"}
        }}
      >
        <Input placeholder='Symbol' value={addTrader?.symbol || ""} onChange={(e) => setAddTrader(old => ({...old, symbol: e.target.value}))}/> 
        <Input placeholder='Amount' type="number" value={addTrader?.baseAmountIn || ""} onChange={(e) => setAddTrader(old => ({...old, baseAmountIn: e.target.value}))}/> 
        <Input placeholder='Leverage' type="number" value={addTrader?.leverage || 10} onChange={(e) => setAddTrader(old => ({...old, leverage: e.target.value}))}/> 
        <div className={styles.inputContainer}>
          <Select defaultValue="UNLIMITED" style={{width: "100%"}} options={
            [
              {label: "Unlimited", value: "UNLIMITED"}, 
              {label: "Limited", value: "LIMITED"},
              {label: "Range", value: "RANGE"},
              {label: "Timed", value: "TIMED"},
            ]
          } onChange={(val) => setAddTrader(old => ({...old, type: val}))}/>
        </div>
        {(addTrader?.type === "TIMED") && <Input placeholder='Hours' type="number" value={addTrader?.hours || ""} onChange={(e) => setAddTrader(old => ({...old, hours: e.target.value}))}/>}
        {addTrader?.type === "LIMITED" && <Input placeholder='Aim' type="number" value={addTrader?.aim || ""} onChange={(e) => setAddTrader(old => ({...old, aim: e.target.value}))}/>}
        {addTrader?.type === "RANGE" && <Input placeholder='Coverage' type="number" value={addTrader?.coverage || ""} onChange={(e) => setAddTrader(old => ({...old, coverage: e.target.value}))}/>}
        {addTrader?.type !== "UNLIMITED" && <Input placeholder='Lives' type="number" value={addTrader?.lives || ""} onChange={(e) => setAddTrader(old => ({...old, lives: e.target.value}))}/>} 
        <Input placeholder='Take Profit' type="number" value={addTrader?.takeProfit || ""} onChange={(e) => setAddTrader(old => ({...old, takeProfit: e.target.value}))}/> 
        <Input placeholder='Step Size' type="number" value={addTrader?.stepSize || ""} onChange={(e) => setAddTrader(old => ({...old, stepSize: e.target.value}))}/> 
        <Input placeholder='Stop Loss' type="number" value={addTrader?.stopLoss || ""} onChange={(e) => setAddTrader(old => ({...old, stopLoss: e.target.value}))}/> 
        <div className={styles.inputContainer}>
          <Select defaultValue="TESTING" style={{width: "100%"}} options={[{label: "Testing", value: "TESTING"}, {label: "Live", value: "LIVE"}]} onChange={(val) => setAddTrader(old => ({...old, mode: val}))}/>
        </div>
        <div className={styles.inputContainer}>
          <Select defaultValue="BOTH" style={{width: "100%"}} options={[{label: "Both", value: "BOTH"}, {label: "One Way", value: "ONEWAY"}]} onChange={(val) => setAddTrader(old => ({...old, direction: val}))}/>
        </div>
      </Modal>
    </div>
  );
}

export default AddTrader;
